// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-markdown-template-tsx": () => import("/opt/build/repo/src/templates/markdown-template.tsx" /* webpackChunkName: "component---src-templates-markdown-template-tsx" */),
  "component---src-pages-impressum-tsx": () => import("/opt/build/repo/src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photos-tsx": () => import("/opt/build/repo/src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

